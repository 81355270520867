import React from "react";
import MyImage from "./webAppLogo.png";
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";

const NavigationModule = () => {
  const firebaseConfig = {
    apiKey: "AIzaSyAR3edMCQESqbqPk7-BmERnaNyqfSTMOyw",
    authDomain: "diversset-portfolio.firebaseapp.com",
    databaseURL:
      "https://diversset-portfolio-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "diversset-portfolio",
    storageBucket: "diversset-portfolio.appspot.com",
    messagingSenderId: "861960906123",
    appId: "1:861960906123:web:b1214019c2226793ab7b8e",
    measurementId: "G-KRVM7BJ1SP",
  };
  const app = initializeApp(firebaseConfig);
  const auth = getAuth();
  const user = auth.currentUser;

  return (
    <div className="navigationBar">
      <div className="imageWithText">
        <img className="image" src={MyImage} alt="Diversset logo" />
        <h1>
          <a
            style={{ textDecoration: "none" }}
            href="https://diversset.quora.com"
          >
            DIVERSSET
          </a>
        </h1>
      </div>

      <h3 id="blog">
        <a
          style={{ textDecoration: "none" }}
          href="https://medium.com/@Sanji_vals"
        >
          INVESTOR BLOG
        </a>
      </h3>
      <h3 id="userProfile">
        <a
          style={{ textDecoration: "none" }}
          href="https://diversset.com/iosApps.html"
        >
          iOS APPS
        </a>
      </h3>
      <h3 id="home">
        <a
          style={{ textDecoration: "none" }}
          href="https://diversset.com"
          onClick={signOut(auth)}
        >
          TO HOME
        </a>
      </h3>
    </div>
  );
};
export default NavigationModule;
