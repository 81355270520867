import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as dataServices from "./sourceForEndpoint-services";
// import * as endpoints from "./Endpoints";

export const getFilteredAssets = createAsyncThunk(
  "filtered/getData",
  dataServices.getFilteredAssetsService
);

export const getStockPrices = createAsyncThunk(
  "filtered/getStockPrices",
  dataServices.getStockPricesService
);

export const getBlogPosts = createAsyncThunk(
  "filtered/getBlogPosts",
  dataServices.getBlogPostsService
);

const filteredSlice = createSlice({
  name: "sourceForEndpoint",
  initialState: {
    loading: false,
    filteredStocksAndETF: [],
    filteredPrices: [],
    weights: [],
    return: 0,
    risk: 0,
    requiredReturn: 0,
    error: "",
    selectedTickers: [],
    blogPosts: [],
  },
  reducers: {
    requiredReturnPut(state, action) {
      state.requiredReturn = action.payload.requiredReturn;
    },
    weightsArray(state, action) {
      state.weights = action.payload.weights;
    },
    riskAndReturnSet(state, action) {
      state.risk = action.payload.risk;
      state.return = action.payload.return;
    },
    selectedTickers(state, action) {
      state.selectedTickers = action.payload.selectedTickers;
    },
  },
  extraReducers: {
    [getFilteredAssets.pending]: (state, action) => {
      state.loading = true;
    },
    [getFilteredAssets.fulfilled]: (state, action) => {
      // console.log(action.payload);
      state.loading = false;
      state.filteredStocksAndETF = action.payload;
      state.error = "";
    },
    [getFilteredAssets.rejected]: (state, action) => {
      state.loading = false;
      state.filteredStocksAndETF = [];
      state.error = action.error.message;
    },
    [getStockPrices.pending]: (state, action) => {
      state.loading = true;
    },
    [getStockPrices.fulfilled]: (state, action) => {
      state.loading = false;
      state.filteredPrices = action.payload;
      state.error = "";
    },
    [getStockPrices.rejected]: (state, action) => {
      state.loading = false;
      state.filteredPrices = [];
      state.error = action.error.message;
    },
    [getBlogPosts.pending]: (state, action) => {
      state.loading = true;
    },
    [getBlogPosts.fulfilled]: (state, action) => {
      state.loading = false;
      state.blogPosts = action.payload;
      state.error = "";
    },
    [getBlogPosts.rejected]: (state, action) => {
      state.loading = false;
      state.blogPosts = [];
      state.error = action.error.message;
    },
  },
});

export const actionsForDatas = filteredSlice.actions;

export default filteredSlice.reducer;
