import React from "react";
import { useParams } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { collection, getDocs, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDetectAdBlock } from "adblock-detect-react";
import AdsBlocker from "./AdsBlocker";
import Ads from "./AdsComponent";
import { Helmet } from "react-helmet";

const BlogPost = ({ onBlockClicked }) => {
  const adBlockDetected = useDetectAdBlock();
  const { heading } = useParams();
  const firebaseConfig = {
    apiKey: "AIzaSyAR3edMCQESqbqPk7-BmERnaNyqfSTMOyw",
    authDomain: "diversset-portfolio.firebaseapp.com",
    databaseURL:
      "https://diversset-portfolio-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "diversset-portfolio",
    storageBucket: "diversset-portfolio.appspot.com",
    messagingSenderId: "861960906123",
    appId: "1:861960906123:web:b1214019c2226793ab7b8e",
    measurementId: "G-KRVM7BJ1SP",
  };
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const [post, setPost] = useState([]);

  const sanitizeHtml = (html) => {
    return html;
  };
  const fetchBlogPost = async (heading) => {
    try {
      const blogRef = collection(db, "blog");
      const q = query(blogRef, where("heading", "==", heading));

      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        console.log("No matching documents.");
        return null;
      }

      const docRef = querySnapshot.docs[0];

      const postData = docRef.data();

      return postData;
    } catch (error) {
      console.error("Error fetching data:", error);
      return null;
    }
  };
  useEffect(() => {
    fetchBlogPost(heading).then((data) => {
      if (data) {
        setPost(data);
      }
    });
  }, [heading]);
  if (!post) {
    return null; // or loading indicator
  }
  return (
    <div>
      <Helmet>
        <meta property="og:title" content={heading || "Stock Market Blog"} />
        <meta
          property="og:description"
          content="Stock market-related blog. Equity valuation, risk management, portfolio management, trading."
        />
        {post.picture && (
          <meta
            property="og:image"
            content={`data:image/jpeg;base64,${post.picture}`}
          />
        )}
        <meta
          property="og:url"
          content={`https://diversset.com/blog/${heading}`}
        />
        <meta property="og:type" content="article" />
      </Helmet>
      {adBlockDetected ? (
        <AdsBlocker />
      ) : (
        <div>
          <Ads />
          <div className="upperContentAndButton">
            <Link to="/stockMarketBlog">
              <button className="tryApp1" onClick={onBlockClicked}>
                Blog
              </button>
            </Link>
            <h1 className="helpLabel">STOCK MARKET BLOG</h1>
          </div>
          <h3 className="subHeading">
            Stock market-related blog. Equity valuation, risk management,
            portfolio management, trading.
          </h3>
          <div className="buttonContainer">
            <div className="blog-list">
              <div className="blog-post">
                <h1>{heading.toUpperCase()}</h1>
                {/* Add the content of the individual blog post here */}
                <div>
                  <img
                    src={`data:image/jpeg;base64,${post.picture}`}
                    alt={`Post image`}
                  />
                  <div
                    className="postBody"
                    dangerouslySetInnerHTML={{
                      __html: sanitizeHtml(post.body),
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BlogPost;
