import React from "react";
import { useState } from "react";
import MarketForecasts from "./MarketForecasts";

const MainStoryModule = (properties) => {
  const [clicked, setClicked] = useState(false);
  const handleImageClick = () => {
    setClicked(false);
    console.log("Image clicked! Action performed in another component.");
  };

  function getBody() {
    // console.log(properties.body);
    // alert(properties.body)
    setClicked(true);
  }
  return (
    <div>
      {clicked === false ? (
        <div className="mainStoryScreen">
          <button className="tryApp2" onClick={getBody}>
            {properties.header}
          </button>
        </div>
      ) : (
        <MarketForecasts
          body={properties.body}
          header={properties.header}
          onClickAction={handleImageClick}
        />
      )}
    </div>
  );
};
export default MainStoryModule;
