import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { collection, getDocs } from "firebase/firestore";

export const getFilteredAssetsService = async ({ marketCap, beta }) => {
  const response = await fetch(
    `https://financialmodelingprep.com/api/v3/stock-screener?marketCapMoreThan=${marketCap}&betaMoreThan=${beta}&volumeMoreThan=10000&limit=1000&apikey=ac56c004479c7cbfc7734b90d6395a3c`
  );
  if (!response.ok) {
    throw new Error("data cannot be fetched");
  } else {
    const dats = await response.json();
    const items = dats.map((dat) => {
      return {
        companyName: dat.companyName,
        symbol: dat.symbol,
        marketCap: dat.marketCap,
        beta: dat.beta,
        country: dat.country,
        isEtf: dat.isEtf,
      };
    });
    try {
      // console.log(items);
      return items;
    } catch (error) {
      console.log("no data");
    }
  }
};

export const getStockPricesService = async ({
  one,
  two,
  three,
  four,
  five,
  six,
  seven,
  eight,
  nine,
  ten,
  pastDate,
  currentDate,
}) => {
  // console.log(
  //   one,
  //   two,
  //   three,
  //   four,
  //   five,
  //   six,
  //   seven,
  //   eight,
  //   nine,
  //   ten,
  //   pastDate,
  //   currentDate
  // );
  const response = await fetch(
    `https://financialmodelingprep.com/api/v3/historical-price-full/${one},${two},${three},${four},${five}?from=${pastDate}&to=${currentDate}&apikey=ac56c004479c7cbfc7734b90d6395a3c`
  );
  const response2 = await fetch(
    `https://financialmodelingprep.com/api/v3/historical-price-full/${six},${seven},${eight},${nine},${ten}?from=${pastDate}&to=${currentDate}&apikey=ac56c004479c7cbfc7734b90d6395a3c`
  );
  if (!response.ok && !response2.ok) {
    throw new Error("data cannot be fetched");
  } else {
    const dats = await response.json();
    const dats2 = await response2.json();
    const assetInfo1 = dats.historicalStockList[0].historical.map((it) => {
      return it.close;
    });
    const assetInfo2 = dats.historicalStockList[1].historical.map((it) => {
      return it.close;
    });
    const assetInfo3 = dats.historicalStockList[2].historical.map((it) => {
      return it.close;
    });
    const assetInfo4 = dats.historicalStockList[3].historical.map((it) => {
      return it.close;
    });
    const assetInfo5 = dats.historicalStockList[4].historical.map((it) => {
      return it.close;
    });
    const assetInfo6 = dats2.historicalStockList[0].historical.map((it) => {
      return it.close;
    });
    const assetInfo7 = dats2.historicalStockList[1].historical.map((it) => {
      return it.close;
    });
    const assetInfo8 = dats2.historicalStockList[2].historical.map((it) => {
      return it.close;
    });
    const assetInfo9 = dats2.historicalStockList[3].historical.map((it) => {
      return it.close;
    });
    const assetInfo10 = dats2.historicalStockList[4].historical.map((it) => {
      return it.close;
    });

    try {
      // console.log(
      //   assetInfo1,
      //   assetInfo2,
      //   assetInfo3,
      //   assetInfo4,
      //   assetInfo5,
      //   assetInfo6,
      //   assetInfo7,
      //   assetInfo8,
      //   assetInfo9,
      //   assetInfo10
      // );
      return {
        assetInfo1,
        assetInfo2,
        assetInfo3,
        assetInfo4,
        assetInfo5,
        assetInfo6,
        assetInfo7,
        assetInfo8,
        assetInfo9,
        assetInfo10,
      };
    } catch (error) {
      console.log("no data");
    }
  }
};

export const getBlogPostsService = async () => {
  const firebaseConfig = {
    apiKey: "AIzaSyAR3edMCQESqbqPk7-BmERnaNyqfSTMOyw",
    authDomain: "diversset-portfolio.firebaseapp.com",
    databaseURL:
      "https://diversset-portfolio-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "diversset-portfolio",
    storageBucket: "diversset-portfolio.appspot.com",
    messagingSenderId: "861960906123",
    appId: "1:861960906123:web:b1214019c2226793ab7b8e",
    measurementId: "G-KRVM7BJ1SP",
  };
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);

  const colRef = collection(db, "blog");
  const snapshots = await getDocs(colRef);

  if (snapshots.empty) {
    console.log("no data in the blog");
    return [];
  }
  const docs = snapshots.docs.map((doc) => doc.data());
  const items = docs.map((doc) => {
    return {
      body: doc.body,
      heading: doc.heading,
      picture: doc.picture,
    };
  });
  try {
    // console.log(items);
    return items;
  } catch (error) {
    console.log("no posts in the blog");
  }
};
