import React, { useRef } from "react";
import copy from "clipboard-copy";

const CryptoAddressComponent = ({ addresses }) => {
  const handleTextClick = (address) => {
    copy(address);
    alert("Crypto address copied to clipboard!");
  };

  return (
    <div>
      {addresses.map(({ label, address }, index) => (
        <div key={index}>
          <h1
            className="twitterText"
            onClick={() => handleTextClick(address)}
            style={{ cursor: "pointer" }}
          >
            <strong>{label}</strong>
          </h1>
        </div>
      ))}
    </div>
  );
};

export default CryptoAddressComponent;
