import { configureStore } from "@reduxjs/toolkit";
import filteredSlice from "./sourceForEndpoint";

const store = configureStore({
  reducer: {
    filtered: filteredSlice,
  },
});

export default store;
