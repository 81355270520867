import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import { actionsForDatas } from "./sourceForEndpoint";
import Ads from "../AdsComponent";
// import { RWebShare } from "react-web-share";

const EfficientPortfolio = ({ homeClicked }) => {
  const results = useSelector((state) => state.filtered.filteredStocksAndETF);
  const closePrices = useSelector((state) => state.filtered.filteredPrices);
  const returnReq = useSelector((state) => state.filtered.requiredReturn);
  const weightsForAssets = useSelector((state) => state.filtered.weights);
  const returnPortfolio = useSelector((state) => state.filtered.return);
  const riskPortfolio = useSelector((state) => state.filtered.risk);
  const dispatch = useDispatch();
  const [notification, setNotification] = useState(false);
  const [nameReturn, setNameReturn] = useState("Portfolio's expected Return");
  const selectedTickers = useSelector(
    (state) => state.filtered.selectedTickers
  );
  const customStyles = {
    columns: {
      style: {
        minHeight: "40px",
      },
    },
    headRow: {
      style: {
        backgroundColor: "#DBDEFC", // Replace with your desired background color
      },
    },
  };
  createTheme(
    "solarized",
    {
      text: {
        primary: "#102F47",
        // secondary: "#FFFFFF",
      },
      background: {
        default: "#FFFFFF",
      },

      divider: {
        default: "#102F47",
      },
    },
    "dark"
  );
  var PortfolioAllocation = require("portfolio-allocation");
  var firstTenArray = [];
  var percentageChange1 = [];
  var percentageChange2 = [];
  var percentageChange3 = [];
  var percentageChange4 = [];
  var percentageChange5 = [];
  var percentageChange6 = [];
  var percentageChange7 = [];
  var percentageChange8 = [];
  var percentageChange9 = [];
  var percentageChange10 = [];
  var percentageChangeStock1 = [];
  var percentageChangeStock2 = [];
  var percentageChangeStock3 = [];
  var percentageChangeStock4 = [];
  var percentageChangeStock5 = [];
  var percentageChangeStock6 = [];
  var percentageChangeStock7 = [];
  var percentageChangeStock8 = [];
  var percentageChangeStock9 = [];
  var percentageChangeStock10 = [];
  var earlyPercentageChangeStock1;
  var earlyPercentageChangeStock2;
  var earlyPercentageChangeStock3;
  var earlyPercentageChangeStock4;
  var earlyPercentageChangeStock5;
  var earlyPercentageChangeStock6;
  var earlyPercentageChangeStock7;
  var earlyPercentageChangeStock8;
  var earlyPercentageChangeStock9;
  var earlyPercentageChangeStock10;
  var weightsArray = [];
  var portfolioReturn;
  var portfolioVariance;
  var variance;
  var portfolioStandardDeviation;
  var stDev;
  var firstTenArray = [];
  var returnNumber;
  var weightsArrayWith2Decimals = [];
  var covMatrix = [];
  var returnAdded;

  useEffect(() => {
    if (closePrices.assetInfo1) {
      calculatePercentageChange();
    } else {
      alert(
        "No data. Please check the internet connection or maybe one of your selected stocks has no trading data, or the trading range is smaller than that of other stocks that you have selected. Please choose other stocks or apply filters on the first page."
      );
    }
  }, []);
  useEffect(() => {
    if (earlyPercentageChangeStock10) {
    }
  }, []);
  useEffect(() => {
    if (earlyPercentageChangeStock10) {
      calculateTheVarianceCovarianceMatrix();
    }
  }, []);
  useEffect(() => {
    if (covMatrix) {
      calculateThePortfolioData();
    }
  }, []);
  function goBack() {
    window.location.reload();
  }
  if (selectedTickers.length == 10) {
    firstTenArray = selectedTickers;
  } else {
    firstTenArray = results.slice(0, 10);
  }
  const columns = [
    {
      name: "Company Name",
      selector: (row) => row.companyName,
    },
    {
      name: "Ticker",
      selector: (row) => row.symbol,
    },
    {
      name: "% of stock in your portfolio",
      selector: (row) => row.percentage,
    },
  ];

  const data = [
    {
      id: 0,
      companyName: firstTenArray[0].companyName,
      symbol: firstTenArray[0].symbol,
      percentage: `${weightsForAssets[0] * 100}%`,
    },
    {
      id: 1,
      companyName: firstTenArray[1].companyName,
      symbol: firstTenArray[1].symbol,
      percentage: `${weightsForAssets[1] * 100}%`,
    },
    {
      id: 2,
      companyName: firstTenArray[2].companyName,
      symbol: firstTenArray[2].symbol,
      percentage: `${weightsForAssets[2] * 100}%`,
    },
    {
      id: 3,
      companyName: firstTenArray[3].companyName,
      symbol: firstTenArray[3].symbol,
      percentage: `${weightsForAssets[3] * 100}%`,
    },
    {
      id: 4,
      companyName: firstTenArray[4].companyName,
      symbol: firstTenArray[4].symbol,
      percentage: `${weightsForAssets[4] * 100}%`,
    },
    {
      id: 5,
      companyName: firstTenArray[5].companyName,
      symbol: firstTenArray[5].symbol,
      percentage: `${weightsForAssets[5] * 100}%`,
    },
    {
      id: 6,
      companyName: firstTenArray[6].companyName,
      symbol: firstTenArray[6].symbol,
      percentage: `${weightsForAssets[6] * 100}%`,
    },
    {
      id: 7,
      companyName: firstTenArray[7].companyName,
      symbol: firstTenArray[7].symbol,
      percentage: `${weightsForAssets[7] * 100}%`,
    },
    {
      id: 8,
      companyName: firstTenArray[8].companyName,
      symbol: firstTenArray[8].symbol,
      percentage: `${weightsForAssets[8] * 100}%`,
    },
    {
      id: 9,
      companyName: firstTenArray[9].companyName,
      symbol: firstTenArray[9].symbol,
      percentage: `${weightsForAssets[9] * 100}%`,
    },
  ];

  const columns2 = [
    {
      name: nameReturn,
      selector: (row) => row.return,
    },
    {
      name: "Portfolio's expected volatility",
      selector: (row) => row.volatility,
    },
  ];
  const data2 = [
    {
      id: 0,
      return: `${returnPortfolio}%`,
      volatility: `${riskPortfolio}%`,
    },
  ];

  async function calculatePercentageChange() {
    closePrices.assetInfo1.map((currVal, index) => {
      const prevVal = closePrices.assetInfo1[index - 1];
      percentageChange1.push(((prevVal - currVal) / currVal) * 1);
      percentageChangeStock1 = percentageChange1.filter((elm) => elm);
      return percentageChangeStock1;
    });
    closePrices.assetInfo2.map((currVal, index) => {
      const prevVal = closePrices.assetInfo2[index - 1];
      percentageChange2.push(((prevVal - currVal) / currVal) * 1);
      percentageChangeStock2 = percentageChange2.filter((elm) => elm);
      return percentageChangeStock2;
    });
    closePrices.assetInfo3.map((currVal, index) => {
      const prevVal = closePrices.assetInfo3[index - 1];
      percentageChange3.push(((prevVal - currVal) / currVal) * 1);
      percentageChangeStock3 = percentageChange3.filter((elm) => elm);
      return percentageChangeStock3;
    });
    closePrices.assetInfo4.map((currVal, index) => {
      const prevVal = closePrices.assetInfo4[index - 1];
      percentageChange4.push(((prevVal - currVal) / currVal) * 1);
      percentageChangeStock4 = percentageChange4.filter((elm) => elm);
      return percentageChangeStock4;
    });
    closePrices.assetInfo5.map((currVal, index) => {
      const prevVal = closePrices.assetInfo5[index - 1];
      percentageChange5.push(((prevVal - currVal) / currVal) * 1);
      percentageChangeStock5 = percentageChange5.filter((elm) => elm);
      return percentageChangeStock5;
    });
    closePrices.assetInfo6.map((currVal, index) => {
      const prevVal = closePrices.assetInfo6[index - 1];
      percentageChange6.push(((prevVal - currVal) / currVal) * 1);
      percentageChangeStock6 = percentageChange6.filter((elm) => elm);
      return percentageChangeStock6;
    });
    closePrices.assetInfo7.map((currVal, index) => {
      const prevVal = closePrices.assetInfo7[index - 1];
      percentageChange7.push(((prevVal - currVal) / currVal) * 1);
      percentageChangeStock7 = percentageChange7.filter((elm) => elm);
      return percentageChangeStock7;
    });
    closePrices.assetInfo8.map((currVal, index) => {
      const prevVal = closePrices.assetInfo8[index - 1];
      percentageChange8.push(((prevVal - currVal) / currVal) * 1);
      percentageChangeStock8 = percentageChange8.filter((elm) => elm);
      return percentageChangeStock8;
    });
    closePrices.assetInfo9.map((currVal, index) => {
      const prevVal = closePrices.assetInfo9[index - 1];
      percentageChange9.push(((prevVal - currVal) / currVal) * 1);
      percentageChangeStock9 = percentageChange9.filter((elm) => elm);
      return percentageChangeStock9;
    });
    closePrices.assetInfo10.map((currVal, index) => {
      const prevVal = closePrices.assetInfo10[index - 1];
      percentageChange10.push(((prevVal - currVal) / currVal) * 1);
      percentageChangeStock10 = percentageChange10.filter((elm) => elm);
      return percentageChangeStock10;
    });
    const first = closePrices.assetInfo1[0];
    const last = closePrices.assetInfo1[closePrices.assetInfo1.length - 1];
    earlyPercentageChangeStock1 = (first - last) / last;
    const first2 = closePrices.assetInfo2[0];
    const last2 = closePrices.assetInfo2[closePrices.assetInfo2.length - 1];
    earlyPercentageChangeStock2 = (first2 - last2) / last2;
    const first3 = closePrices.assetInfo3[0];
    const last3 = closePrices.assetInfo3[closePrices.assetInfo3.length - 1];
    earlyPercentageChangeStock3 = (first3 - last3) / last3;
    const first4 = closePrices.assetInfo4[0];
    const last4 = closePrices.assetInfo4[closePrices.assetInfo4.length - 1];
    earlyPercentageChangeStock4 = (first4 - last4) / last4;
    const first5 = closePrices.assetInfo5[0];
    const last5 = closePrices.assetInfo5[closePrices.assetInfo5.length - 1];
    earlyPercentageChangeStock5 = (first5 - last5) / last5;
    const first6 = closePrices.assetInfo6[0];
    const last6 = closePrices.assetInfo6[closePrices.assetInfo6.length - 1];
    earlyPercentageChangeStock6 = (first6 - last6) / last6;
    const first7 = closePrices.assetInfo7[0];
    const last7 = closePrices.assetInfo7[closePrices.assetInfo7.length - 1];
    earlyPercentageChangeStock7 = (first7 - last7) / last7;
    const first8 = closePrices.assetInfo8[0];
    const last8 = closePrices.assetInfo8[closePrices.assetInfo8.length - 1];
    earlyPercentageChangeStock8 = (first8 - last8) / last8;
    const first9 = closePrices.assetInfo9[0];
    const last9 = closePrices.assetInfo9[closePrices.assetInfo9.length - 1];
    earlyPercentageChangeStock9 = (first9 - last9) / last9;
    const first10 = closePrices.assetInfo10[0];
    const last10 = closePrices.assetInfo10[closePrices.assetInfo10.length - 1];
    earlyPercentageChangeStock10 = (first10 - last10) / last10;
  }
  async function calculateTheVarianceCovarianceMatrix() {
    let lengths = [
      percentageChangeStock1.length,
      percentageChangeStock2.length,
      percentageChangeStock3.length,
      percentageChangeStock4.length,
      percentageChangeStock5.length,
      percentageChangeStock6.length,
      percentageChangeStock7.length,
      percentageChangeStock8.length,
      percentageChangeStock9.length,
      percentageChangeStock10.length,
    ];

    let minLength = Math.min(...lengths);

    percentageChangeStock1.splice(minLength);
    percentageChangeStock2.splice(minLength);
    percentageChangeStock3.splice(minLength);
    percentageChangeStock4.splice(minLength);
    percentageChangeStock5.splice(minLength);
    percentageChangeStock6.splice(minLength);
    percentageChangeStock7.splice(minLength);
    percentageChangeStock8.splice(minLength);
    percentageChangeStock9.splice(minLength);
    percentageChangeStock10.splice(minLength);

    const data = [
      percentageChangeStock1,
      percentageChangeStock2,
      percentageChangeStock3,
      percentageChangeStock4,
      percentageChangeStock5,
      percentageChangeStock6,
      percentageChangeStock7,
      percentageChangeStock8,
      percentageChangeStock9,
      percentageChangeStock10,
    ];

    var means = [];
    for (var i = 0; i < data.length; i++) {
      means.push(data[i].reduce((a, b) => a + b, 0) / data[i].length);
    }
    // Step 3: Calculate variance-covariance matrix
    for (var i = 0; i < data.length; i++) {
      covMatrix[i] = [];
      for (var j = 0; j < data.length; j++) {
        var cov = 0;
        for (var k = 0; k < data[i].length; k++) {
          cov += (data[i][k] - means[i]) * (data[j][k] - means[j]);
        }
        covMatrix[i][j] = cov / (data[i].length - 1);
      }
    }
    // console.log(covMatrix);
  }
  if (!notification) {
    returnNumber = returnReq / 100;
  } else {
    returnAdded = 1;
  }

  // console.log(returnNumber);
  async function calculateThePortfolioData() {
    try {
      var w = PortfolioAllocation.meanVarianceOptimizationWeights(
        [
          earlyPercentageChangeStock1,
          earlyPercentageChangeStock2,
          earlyPercentageChangeStock3,
          earlyPercentageChangeStock4,
          earlyPercentageChangeStock5,
          earlyPercentageChangeStock6,
          earlyPercentageChangeStock7,
          earlyPercentageChangeStock8,
          earlyPercentageChangeStock9,
          earlyPercentageChangeStock10,
        ],
        [
          covMatrix[0],
          covMatrix[1],
          covMatrix[2],
          covMatrix[3],
          covMatrix[4],
          covMatrix[5],
          covMatrix[6],
          covMatrix[7],
          covMatrix[8],
          covMatrix[9],
        ],
        {
          constraints: {
            return: returnNumber,
            // riskTolerance: 0,

            maxWeights: [
              0.25, 0.25, 0.25, 0.25, 0.25, 0.25, 0.25, 0.25, 0.25, 0.25,
            ],
          },
        }
      );
      let weight1 = w[0];
      let weight2 = w[1];
      let weight3 = w[2];
      let weight4 = w[3];
      let weight5 = w[4];
      let weight6 = w[5];
      let weight7 = w[6];
      let weight8 = w[7];
      let weight9 = w[8];
      let weight10 = w[9];
      weightsArray.push(
        weight1,
        weight2,
        weight3,
        weight4,
        weight5,
        weight6,
        weight7,
        weight8,
        weight9,
        weight10
      );
      weightsArrayWith2Decimals = weightsArray.map(function (each_element) {
        return Number(each_element.toFixed(2));
      });
      // console.log(weightsArray);
      setTimeout(() => {
        dispatch(
          actionsForDatas.weightsArray({ weights: weightsArrayWith2Decimals })
        );
      }, 500);

      portfolioReturn =
        weight1 * earlyPercentageChangeStock1 +
        weight2 * earlyPercentageChangeStock2 +
        weight3 * earlyPercentageChangeStock3 +
        weight4 * earlyPercentageChangeStock4 +
        weight5 * earlyPercentageChangeStock5 +
        weight6 * earlyPercentageChangeStock6 +
        weight7 * earlyPercentageChangeStock7 +
        weight8 * earlyPercentageChangeStock8 +
        weight9 * earlyPercentageChangeStock9 +
        weight10 * earlyPercentageChangeStock10;
      let transpose1 =
        covMatrix[0][0] * weightsArray[0] +
        covMatrix[0][1] * weightsArray[0] +
        covMatrix[0][2] * weightsArray[0] +
        covMatrix[0][3] * weightsArray[0] +
        covMatrix[0][4] * weightsArray[0] +
        covMatrix[0][5] * weightsArray[0] +
        covMatrix[0][6] * weightsArray[0] +
        covMatrix[0][7] * weightsArray[0] +
        covMatrix[0][8] * weightsArray[0] +
        covMatrix[0][9] * weightsArray[0];
      let transpose2 =
        covMatrix[1][0] * weightsArray[1] +
        covMatrix[1][1] * weightsArray[1] +
        covMatrix[1][2] * weightsArray[1] +
        covMatrix[1][3] * weightsArray[1] +
        covMatrix[1][4] * weightsArray[1] +
        covMatrix[1][5] * weightsArray[1] +
        covMatrix[1][6] * weightsArray[1] +
        covMatrix[1][7] * weightsArray[1] +
        covMatrix[1][8] * weightsArray[1] +
        covMatrix[1][9] * weightsArray[1];
      let transpose3 =
        covMatrix[2][0] * weightsArray[2] +
        covMatrix[2][1] * weightsArray[2] +
        covMatrix[2][2] * weightsArray[2] +
        covMatrix[2][3] * weightsArray[2] +
        covMatrix[2][4] * weightsArray[2] +
        covMatrix[2][5] * weightsArray[2] +
        covMatrix[2][6] * weightsArray[2] +
        covMatrix[2][7] * weightsArray[2] +
        covMatrix[2][8] * weightsArray[2] +
        covMatrix[2][9] * weightsArray[2];
      let transpose4 =
        covMatrix[3][0] * weightsArray[3] +
        covMatrix[3][1] * weightsArray[3] +
        covMatrix[3][2] * weightsArray[3] +
        covMatrix[3][3] * weightsArray[3] +
        covMatrix[3][4] * weightsArray[3] +
        covMatrix[3][5] * weightsArray[3] +
        covMatrix[3][6] * weightsArray[3] +
        covMatrix[3][7] * weightsArray[3] +
        covMatrix[3][8] * weightsArray[3] +
        covMatrix[3][9] * weightsArray[3];
      let transpose5 =
        covMatrix[4][0] * weightsArray[4] +
        covMatrix[4][1] * weightsArray[4] +
        covMatrix[4][2] * weightsArray[4] +
        covMatrix[4][3] * weightsArray[4] +
        covMatrix[4][4] * weightsArray[4] +
        covMatrix[4][5] * weightsArray[4] +
        covMatrix[4][6] * weightsArray[4] +
        covMatrix[4][7] * weightsArray[4] +
        covMatrix[4][8] * weightsArray[4] +
        covMatrix[4][9] * weightsArray[4];
      let transpose6 =
        covMatrix[5][0] * weightsArray[5] +
        covMatrix[5][1] * weightsArray[5] +
        covMatrix[5][2] * weightsArray[5] +
        covMatrix[5][3] * weightsArray[5] +
        covMatrix[5][4] * weightsArray[5] +
        covMatrix[5][5] * weightsArray[5] +
        covMatrix[5][6] * weightsArray[5] +
        covMatrix[5][7] * weightsArray[5] +
        covMatrix[5][8] * weightsArray[5] +
        covMatrix[5][9] * weightsArray[5];
      let transpose7 =
        covMatrix[6][0] * weightsArray[6] +
        covMatrix[6][1] * weightsArray[6] +
        covMatrix[6][2] * weightsArray[6] +
        covMatrix[6][3] * weightsArray[6] +
        covMatrix[6][4] * weightsArray[6] +
        covMatrix[6][5] * weightsArray[6] +
        covMatrix[6][6] * weightsArray[6] +
        covMatrix[6][7] * weightsArray[6] +
        covMatrix[6][8] * weightsArray[6] +
        covMatrix[6][9] * weightsArray[6];
      let transpose8 =
        covMatrix[7][0] * weightsArray[7] +
        covMatrix[7][1] * weightsArray[7] +
        covMatrix[7][2] * weightsArray[7] +
        covMatrix[7][3] * weightsArray[7] +
        covMatrix[7][4] * weightsArray[7] +
        covMatrix[7][5] * weightsArray[7] +
        covMatrix[7][6] * weightsArray[7] +
        covMatrix[7][7] * weightsArray[7] +
        covMatrix[7][8] * weightsArray[7] +
        covMatrix[7][9] * weightsArray[7];
      let transpose9 =
        covMatrix[8][0] * weightsArray[8] +
        covMatrix[8][1] * weightsArray[8] +
        covMatrix[8][2] * weightsArray[8] +
        covMatrix[8][3] * weightsArray[8] +
        covMatrix[8][4] * weightsArray[8] +
        covMatrix[8][5] * weightsArray[8] +
        covMatrix[8][6] * weightsArray[8] +
        covMatrix[8][7] * weightsArray[8] +
        covMatrix[8][8] * weightsArray[8] +
        covMatrix[8][9] * weightsArray[8];
      let transpose10 =
        covMatrix[9][0] * weightsArray[9] +
        covMatrix[9][1] * weightsArray[9] +
        covMatrix[9][2] * weightsArray[9] +
        covMatrix[9][3] * weightsArray[9] +
        covMatrix[9][4] * weightsArray[9] +
        covMatrix[9][5] * weightsArray[9] +
        covMatrix[9][6] * weightsArray[9] +
        covMatrix[9][7] * weightsArray[9] +
        covMatrix[9][8] * weightsArray[9] +
        covMatrix[9][9] * weightsArray[9];

      portfolioVariance =
        weight1 * transpose1 +
        weight2 * transpose2 +
        weight3 * transpose3 +
        weight4 * transpose4 +
        weight5 * transpose5 +
        weight6 * transpose6 +
        weight7 * transpose7 +
        weight8 * transpose8 +
        weight9 * transpose9 +
        weight10 * transpose10;
      if (portfolioVariance < 0) {
        variance = portfolioVariance * -1;
      } else {
        variance = portfolioVariance;
      }
      if (portfolioReturn < 0) {
        setNameReturn("Portfolio's expected loss");
      }
      portfolioStandardDeviation = Math.sqrt(variance);
      stDev = portfolioStandardDeviation * 100;
      dispatch(
        actionsForDatas.riskAndReturnSet({
          risk: stDev.toFixed(2),
          return: portfolioReturn.toFixed(2) * 100,
        })
      );
    } catch (error) {
      // console.log(error);

      if (error) {
        returnNumber = returnAdded + returnNumber - 0.01;
        calculateThePortfolioData();
      }
      setNotification(true);
    }
  }

  return (
    <div>
      {!notification ? (
        <div>
          <Ads />
          <div className="upperContentAndButton">
            <button className="tryApp1" onClick={goBack}>
              Back
            </button>
            <h1 className="helpLabel">BUILT EFFICIENT INVESTMENT PORTFOLIO</h1>
          </div>
          <h3 className="subHeading">
            Efficient portfolio built from your selected stocks and ETFs
          </h3>
        </div>
      ) : (
        <div>
          <Ads />
          <div className="upperContentAndButton">
            <button className="tryApp1" onClick={goBack}>
              Home
            </button>
            <h1 className="helpLabel" style={{ color: "red" }}>
              RETURN ISSUE
            </h1>
          </div>
          <h3
            className="subHeading"
            style={{
              color: "red",
              fontSize: "13px",
              width: "50vw",
              marginLeft: "25%",
              marginTop: "1%",
            }}
          >
            {" "}
            No efficien portfolio with a return of {returnReq}%. Maximum return
            and minumum volatility portfolio built
          </h3>
        </div>
      )}

      <div>
        <div className="centeredDataTable">
          <div className="dataTable">
            <DataTable
              columns={columns}
              data={data}
              customStyles={customStyles}
              theme="solarized"
            />
          </div>
        </div>
      </div>
      <div className="centeredDataTable2">
        <div className="dataTable2">
          <DataTable
            columns={columns2}
            data={data2}
            customStyles={customStyles}
            theme="solarized"
          />
        </div>
      </div>
      {/* <RWebShare
            data={{
              text: "Try this wonderful web app to search stocks and ETFs and construct an efficient investment portfolio!!!",
              url: "https://diversset.com/",
              title: "Share the web app",
            }}
            onClick={() => console.log("shared successfully!")}
          >
            <button id="saveButton">SHARE</button>
          </RWebShare> */}
    </div>
  );
};
export default EfficientPortfolio;
