import "./App.scss";
import MainStoryModule from "./Mainstory";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { collection, getDocs } from "firebase/firestore";
import { useEffect, useState } from "react";
import AdsBlocker from "./AdsBlocker";
import { useDetectAdBlock } from "adblock-detect-react";
import Ads from "./AdsComponent";
import { Link } from "react-router-dom";

const ForecasterAndAnalytics = ({ homeClicked }) => {
  const adBlockDetected = useDetectAdBlock();
  const [datasArray, setDatasArray] = useState([]);
  const firebaseConfig = {
    apiKey: "AIzaSyAR3edMCQESqbqPk7-BmERnaNyqfSTMOyw",
    authDomain: "diversset-portfolio.firebaseapp.com",
    databaseURL:
      "https://diversset-portfolio-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "diversset-portfolio",
    storageBucket: "diversset-portfolio.appspot.com",
    messagingSenderId: "861960906123",
    appId: "1:861960906123:web:b1214019c2226793ab7b8e",
    measurementId: "G-KRVM7BJ1SP",
  };
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  useEffect(() => {
    (async () => {
      const colRef = collection(db, "articles");
      const snapshots = await getDocs(colRef);
      const docs = snapshots.docs.map((doc) => doc.data());
      datasArray.push(docs.header);
      setDatasArray(docs);
    })();
  }, []);
  const sorted = datasArray.sort(function (a, b) {
    return new Date(b.postNumber) - new Date(a.postNumber);
  });
  const goHome = () => {
    // Replace 'your_link_here' with the actual URL you want to navigate to
    window.location.href = "https://diversset.com/";
  };
  // console.log(datasArray);
  const gettingTheData = sorted.map((item, pos) => {
    // console.log(item.header)
    // console.log(item.body)
    return (
      <MainStoryModule
        className="mainStoryScreen"
        key={pos}
        header={item.header}
        body={item.body}
      />
    );
  });
  return (
    <div>
      {adBlockDetected ? (
        <AdsBlocker />
      ) : (
        <div>
          <Ads />

          <div className="upperContentAndButton">
            <Link to="/">
              <button className="tryApp1" onClick={homeClicked}>
                Home
              </button>
            </Link>
            <h1 className="helpLabel">FORECASTER AND ANALYST</h1>
          </div>
          <h3 className="subHeading">
            Monthly stock market analytics and forecast. My view and opinion.
          </h3>

          <div className="buttonContainer">{gettingTheData}</div>
          {/* <MyAdsUnit /> */}
          {/* <GoogleAd slot="6984022506" googleAdId="ca-pub-7779023812986289" /> */}
          {/* <GoogleAds slot="6984022506" /> */}

          {/* <Adsense
          client="ca-pub-7779023812986289"
          slot="6984022506"
          style={{ display: "block" }}
          layout="in-article"
          format="fluid"
        /> */}
        </div>
      )}
    </div>
  );
};

export default ForecasterAndAnalytics;
