import React, { useState } from "react";
import MyImage from "./stockTargetPrice.png";
import MyImage2 from "./stocks2Buy.png";
import MyImage3 from "./ballyCatcher.png";
import MyImage4 from "./whatBothersU.png";
import { db, addDoc, collection } from "./firebase";
import { Link } from "react-router-dom";

const IOSAppsComponent = ({ homeClicked }) => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleButtonClick1 = () => {
    // Replace 'your_link_here' with the actual URL you want to navigate to
    window.location.href =
      "https://apps.apple.com/us/app/stock-target-price/id1589969451";
  };
  const handleButtonClick2 = () => {
    // Replace 'your_link_here' with the actual URL you want to navigate to
    window.location.href =
      "https://apps.apple.com/us/app/stocks-2-buy/id6444135874";
  };
  const handleButtonClick3 = () => {
    // Replace 'your_link_here' with the actual URL you want to navigate to
    window.location.href =
      "https://apps.apple.com/kz/app/bally-catcher/id1658863100";
  };
  const handleButtonClick4 = async () => {
    if (email) {
      try {
        await addDoc(collection(db, "emails"), {
          email: email,
          timestamp: new Date(),
        });
        alert("Email uploaded successfully.");
        setEmail("");
      } catch (error) {
        alert("An error occurred. Please try again.");
      }
    } else {
      alert("Email cannot be empty.");
    }
  };
  return (
    <div>
      <div className="upperContentAndButton">
        <Link to="/">
          <button className="tryApp1" onClick={homeClicked}>
            Home
          </button>
        </Link>

        <h1 className="helpLabel">iOS APPLICATIONS FOR STOCK INVESTORS </h1>
      </div>

      {/* <h3 className="subHeading">HAVE QUESTIONS? CONTACT ME.</h3> */}
      <div className="contentsWithApps">
        <div class="image-container">
          <div class="image-box1">
            <p class="image-description">
              Calculate stock growth potential. Share your investment ideas.
              Buy/sell stocks at the right moment.
            </p>
            <img class="firstImage" src={MyImage} alt="Image 1" />

            <button class="image-button" onClick={handleButtonClick1}>
              Try the app
            </button>
          </div>
          <div class="image-box">
            <p class="image-description">
              Know when to buy or sell your stocks. See the stock rating based
              on the Stock sentiment, EPS data and analysts rating.
            </p>
            <img src={MyImage2} alt="Image 1" />

            <button class="image-button" onClick={handleButtonClick2}>
              Try the app
            </button>
          </div>
        </div>
        <div class="image-container">
          <div class="image-box3">
            <p class="image-description">
              Play the game to fight the attacks of panic and anxiety. Calm
              down. Earn 250 scores in the game and get my 11-hour financial
              course for free
            </p>
            <img class="thirdImage" src={MyImage3} alt="Image 1" />

            <button class="image-button" onClick={handleButtonClick3}>
              Try the app
            </button>
          </div>
          <div class="image-box4">
            <p class="image-description">
              Find useful and effective tools that will help you fight your
              panic and anxiety attacks. With this app you will forget how it
              feels to experience continuous waves of panic and anxiety.
            </p>
            <img src={MyImage4} alt="Image 1" />
            <input
              type="text"
              className="text-input"
              placeholder="Leave your email and I will notify you when the app will be ready"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button class="image-button" onClick={handleButtonClick4}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IOSAppsComponent;
