import React from "react";
import { useDetectAdBlock } from "adblock-detect-react";

const AdsBlocker = () => {
  const adBlockDetected = useDetectAdBlock();

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start", // Align content at the top
        backgroundColor: "black",
        color: "white",
        zIndex: 9999, // Make sure it's above other content
      }}
    >
      {adBlockDetected && (
        <p
          style={{
            marginTop: "100px",
            marginLeft: "40px",
            marginRight: "40px",
          }}
        >
          "We have detected that you are using AdsBlocker. Please remove ads
          blocker to use Diversset. This app is free but it has ads. Support us
          by disabling ads blocker. Thank you"
        </p>
      )}
    </div>
  );
};

export default AdsBlocker;
