import React from "react";
import MyImage from "./backgroundImage.png";
import { Link } from "react-router-dom";

const MainPageContent = ({ tryDiverssetClicked }) => {
  return (
    <div className="elementsContainer">
      <div className="texts">
        <h1 className="head-text">
          PROVIDING SIMPLE SOLUTIONS TO COMPLICATED QUESTIONS
        </h1>
        <h1 className="body-text">
          Diversset is the first stock screener, ETF screener and portfolio
          builder that does not require you to have financial knowledge.
          Diversset helps you find great stocks and ETFs tailored to your risk
          appetite and build an efficient investment portfolio. I transferred
          difficult Excel portfolio building models and risk management models
          into this powerful and simple web-based application to provide
          investors with simple answers.
        </h1>

        <Link
          className="linkColor"
          onClick={tryDiverssetClicked}
          to="/stockScreenerAndPortfolioBuilder"
        >
          <button className="tryApp" onClick={tryDiverssetClicked}>
            Try the app
          </button>
        </Link>
      </div>
      <div className="imageContainer">
        <img className="imageBackground" src={MyImage} alt="Diversset logo" />
      </div>
    </div>
  );
};

export default MainPageContent;
