import React, { useState } from "react";
import MyImage from "./backgroundImage.png";
import MyImage2 from "./xImage.png";
import MyImage5 from "./blueskyLogo.png";
import MyImage3 from "./mail.png";
import MyImage4 from "./phone.png";
import MyImage6 from "./bit.png";
import MyImage7 from "./usdt.png";
import { Link } from "react-router-dom";
import CryptoAddressComponent from "./CryptoAdressComponent";
import "./App.scss";

const HelpComponent = ({ homeClicked }) => {
  const emailAddress = "skobzhan@diversset.com";
  const phoneNumber = "+541133695060";
  const bitcoinAddress = "1LL5cSjh5pkxWFXgVkmtLjCSWV49YKoa9m";
  const usdtAddress = "TJLGsdx67pL7RzwaqQNMz1i4EqY59QWE3r";

  const [messages, setMessages] = useState([]);
  const [userInput, setUserInput] = useState("");
  const [showChat, setShowChat] = useState(false);
  const [showReply, setShowReply] = useState(false);
  const toggleChat = () => {
    setShowChat(!showChat);
  };
  const addMessage = (text, isUser) => {
    setMessages([...messages, { text, isUser }]);
  };
  const handleBotResponse = (userInput) => {
    const lowerCaseInput = userInput.toLowerCase();
    if (
      lowerCaseInput.includes("hello") ||
      lowerCaseInput.includes("hi") ||
      lowerCaseInput.includes("stock") ||
      lowerCaseInput.includes("invest") ||
      lowerCaseInput.includes("analysis") ||
      lowerCaseInput.includes("trading") ||
      lowerCaseInput.includes("ideas") ||
      lowerCaseInput.includes("pick") ||
      lowerCaseInput.includes("portfolio") ||
      lowerCaseInput.includes("risk") ||
      lowerCaseInput.includes("screener") ||
      lowerCaseInput.includes("investing guidance") ||
      lowerCaseInput.includes("iOS applocations") ||
      lowerCaseInput.includes("brokerage") ||
      lowerCaseInput.includes("account") ||
      lowerCaseInput.includes("forecast") ||
      lowerCaseInput.includes("minimization") ||
      lowerCaseInput.includes("course")
    ) {
      return (
        <div>
          Hi dear Investor! Click on one of the options below that matches your
          search criteria:
          <br />
          <br />
          1.{" "}
          <Link to="https://www.udemy.com/course/financial-instruments-and-analysis/?referralCode=CFD1565180443B63D1F7">
            Financial course.
          </Link>
          <br />
          <br />
          2.{" "}
          <Link to="https://diversset.com/stockMarketBlog">
            Stock market blog
          </Link>
          <br />
          <br />
          3.{" "}
          <Link to="https://diversset.com/stockScreenerAndPortfolioBuilder">
            Stock screener, portfolio builder
          </Link>
          <br />
          <br />
          4. <Link to="https://diversset.com/ideas">Stock market forecast</Link>
          <br />
          <br />
          5.{" "}
          <Link to="https://www.interactivebrokers.com/referral/sanjar105">
            Open a brokerage account
          </Link>
          <br />
          <br />
          6.{" "}
          <Link to="https://diversset.com/stockMarketiOSApps">
            iOS applications
          </Link>
          <br />
          <br />
          7.{" "}
          <Link to="https://x.com/Sanji_vals/status/1702301446408929546?s=20">
            Investing guidance
          </Link>
        </div>
      );
    }

    return "For specific questions related to finance or for advice, contact me via Whatsup +995 5959 068 50, thank you.";
  };
  const handleSendMessage = () => {
    if (userInput.trim() !== "") {
      setShowReply(true);
      addMessage(userInput, true);
      const botResponse = handleBotResponse(userInput);
      setTimeout(() => {
        addMessage(botResponse, false);
      }, 500);
      setUserInput("");
    }
  };

  const bitcoinAddresses = [
    { label: "Bitcoin address", address: bitcoinAddress },
  ];
  const usdtAddresses = [{ label: "Usdt address", address: usdtAddress }];

  return (
    <div>
      <div className="upperContentAndButton">
        <Link to="/">
          <button className="tryApp1" onClick={homeClicked}>
            Home
          </button>
        </Link>

        <h1 className="helpLabel">HELP</h1>
      </div>
      <h3 className="subHeading">HAVE QUESTIONS? CONTACT ME.</h3>

      <div className="elementsContainer2">
        <div className="texts2">
          <Link
            to="https://twitter.com/Sanji_vals"
            className="removeLinkHighlight"
          >
            <div className="xImageContainer">
              <img className="imageBackgroundX" src={MyImage2} alt="X logo" />
              <h1 className="twitterText">@Sanji_vals</h1>
            </div>
          </Link>
          <Link
            to="https://bsky.app/profile/diversset.com"
            className="removeLinkHighlight"
          >
            <div className="xImageContainer2">
              <img
                className="imageBackgroundX"
                src={MyImage5}
                alt="Bluesky logo"
              />
              <h1 className="twitterText">@diversset.com</h1>
            </div>
          </Link>
          <a href={`mailto:${emailAddress}`} className="removeLinkHighlight">
            <div className="xImageContainer2">
              <img
                className="imageBackgroundX"
                src={MyImage3}
                alt="mail logo"
              />
              <h1 className="twitterText">{emailAddress}</h1>
            </div>
          </a>
          <a
            href={`https://wa.me/${phoneNumber}`}
            className="removeLinkHighlight"
          >
            <div className="xImageContainer2">
              <img
                className="imageBackgroundX"
                src={MyImage4}
                alt="phone logo"
              />
              <h1 className="twitterText">{phoneNumber}</h1>
            </div>
          </a>
          <div style={{ margin: "20px 0" }}>
            <h1>SUPPORT MY PROJECT</h1>
          </div>

          <div className="xImageContainer2">
            <img
              className="imageBackgroundX"
              src={MyImage6}
              alt="Bitcoin logo"
            />

            <CryptoAddressComponent addresses={bitcoinAddresses} />
          </div>
          <div className="xImageContainer2">
            <img className="imageBackgroundX" src={MyImage7} alt="USDT logo" />

            <CryptoAddressComponent addresses={usdtAddresses} />
          </div>
          <div
            className="chatbot-container"
            style={{
              width: showChat ? "250px" : "120px",
              border: "none",
            }}
          >
            <div
              className="chatbot-header"
              onClick={toggleChat}
              style={{
                backgroundColor: showChat ? "#102F47" : "#F1F1F1",
                cursor: "pointer",
              }}
            >
              <div className="avatar"></div>
              <div
                className="title"
                style={{
                  color: showChat ? "#F1F1F1" : "#102F47",
                  cursor: "pointer",
                }}
              >
                Chatbot
              </div>
            </div>
            {showChat && (
              <div>
                <div className="chatbot-body">
                  {showReply && (
                    <div className="chat-messages">
                      {messages.map((message, index) => (
                        <div
                          key={index}
                          className={
                            message.isUser ? "user-message" : "bot-message"
                          }
                        >
                          {message.text}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div className="chatbot-footer">
                  <input
                    type="text"
                    value={userInput}
                    onChange={(e) => setUserInput(e.target.value)}
                    placeholder="Type a message..."
                  />
                  <button onClick={handleSendMessage}>Send</button>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="imageContainer">
          <img className="imageBackground" src={MyImage} alt="Diversset logo" />
        </div>
      </div>
    </div>
  );
};

export default HelpComponent;
