import React from "react";
import { Adsense } from "@ctrl/react-adsense";

const Ads = () => {
  return (
    <div>
      <div>
        <div class="ads-container">
          <div className="right-ad">
            <Adsense
              client="ca-pub-7779023812986289"
              slot="6984022506"
              style={{ display: "block" }}
              layout="in-article"
              format="auto"
            />
            <Adsense />
          </div>

          <div className="left-ad">
            <Adsense
              client="ca-pub-7779023812986289"
              slot="6984022506"
              style={{ display: "block" }}
              layout="in-article"
              format="fluid"
            />
            <Adsense />
          </div>
        </div>
        <div className="bottom-ad">
          <Adsense
            client="ca-pub-7779023812986289"
            slot="6984022506"
            style={{ display: "block" }}
            layout="in-article"
            format="fluid"
          />
          <Adsense />
        </div>
      </div>
    </div>
  );
};
export default Ads;
