import React from "react";
import { useState, useEffect, CSSProperties } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getFilteredAssets,
  actionsForDatas,
  getStockPrices,
} from "./sourceForEndpoint";
import DataTable, { createTheme } from "react-data-table-component";
import EfficientPortfolio from "./EfficientPortfolioBuilder";
import MoonLoader from "react-spinners/ClipLoader";
import "@fontsource/noto-sans-myanmar";
import NavigationModule from "./NavigationElements";
import MyImageRisk from "./investorType.png";
import MyImageReturn from "./returnSelector.png";
import Ads from "../AdsComponent";
import { Link } from "react-router-dom";

const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  marginTop: "15%",
};

const StockScreener = ({ homeClicked }) => {
  const [value4, setValue4] = useState(0);
  const [IndexChange, setIndexChange] = useState(null);
  const [stocksselected, setStocksselected] = useState(false);
  const dispatch = useDispatch();
  const results = useSelector((state) => state.filtered.filteredStocksAndETF);
  const selectedTickers = useSelector(
    (state) => state.filtered.selectedTickers
  );
  const [screenState, setScreenState] = useState(false);
  const [filteredDataState, setFilteredDataState] = useState(true);
  const [screenStatePortfolio, setScreenStatePortfolio] = useState(false);
  const [spinnerLoad, setSpinnerLoad] = useState(false);
  const [spinnerLoad2, setSpinnerLoad2] = useState(false);
  const [buttonState, setButtonState] = useState(false);
  const [investorType, setInvestorType] = useState();
  const [active, setActive] = useState(false);
  var firstTenArray = [];
  var dataSymbol = [];
  var dataTicker = [];
  const customStyles = {
    columns: {
      style: {
        minHeight: "40px",
      },
    },
    headRow: {
      style: {
        backgroundColor: "#DBDEFC", // Replace with your desired background color
      },
    },
  };
  createTheme(
    "solarized",
    {
      text: {
        primary: "#102F47",
        // secondary: "#FFFFFF",
      },
      background: {
        default: "#FFFFFF",
      },

      divider: {
        default: "#102F47",
      },
    },
    "dark"
  );
  if (stocksselected) {
    firstTenArray = selectedTickers;
  } else {
    firstTenArray = results.slice(0, 10);
  }
  var date = new Date();
  date.setDate(date.getDate());
  var dateStringCurrent = date.toISOString().split("T")[0];

  var date = new Date();
  date.setDate(date.getDate() - 900);
  var dateStringPast = date.toISOString().split("T")[0];
  let currentDate = dateStringCurrent;
  let pastDate = dateStringPast;
  useEffect(() => {
    dispatch(getFilteredAssets({}));
  }, []);

  const columns = [
    {
      name: "Company Name",
      selector: (row) => row.companyName,
    },
    {
      name: "Ticker",
      selector: (row) => row.symbol,
    },
    {
      name: "Market Cap",
      selector: (row) => row.marketCap,
      sortable: true,
    },
    {
      name: "Beta",
      selector: (row) => row.beta,
      sortable: true,
    },
    {
      name: "Country",
      selector: (row) => row.country,
    },
  ];
  var data = [];
  results.map((item, index) => {
    data.push({
      id: index,
      companyName: item.companyName,
      symbol: item.symbol,
      marketCap: item.marketCap.toLocaleString(),
      beta: item.beta,
      country: item.country,
    });
  });

  const handleChange = (index) => {
    if (IndexChange == index) {
      setIndexChange(null);
    } else {
      setIndexChange(index);
      // console.log(index);
      setInvestorType(index);
    }
  };
  useEffect(() => {
    if (investorType == 0) {
      dispatch(getFilteredAssets({ marketCap: 100000000000, beta: 0 }));
    }
    if (investorType == 1) {
      dispatch(getFilteredAssets({ marketCap: 10000000000, beta: 2 }));
    }
    if (investorType == 2) {
      dispatch(getFilteredAssets({ marketCap: 10000000000, beta: 1 }));
    }
  }, [investorType]);

  function performAnAction() {
    setActive(true);
    setSpinnerLoad2(true);
    setTimeout(() => {
      setActive(false);
      setSpinnerLoad2(false);
    }, 10000);

    if (results.length > 0) {
      setScreenState(true);
      setScreenStatePortfolio(false);
      // console.log(results);
    } else {
      // console.log(results);
      setScreenState(false);
      alert("No data. Please fill in the data");
    }
  }
  function goBack() {
    setScreenState(false);
    setFilteredDataState(true);
    window.location.reload();
  }
  function setStateForPortfolio() {
    alert(
      "If you want to build a portfolio using your selected stocks you have to choose 10 stocks. If you don't choose stocks we will build you portfolio from 10 the most liquid stocks."
    );
    dispatch(
      getStockPrices({
        one: firstTenArray[0].symbol,
        two: firstTenArray[1].symbol,
        three: firstTenArray[2].symbol,
        four: firstTenArray[3].symbol,
        five: firstTenArray[4].symbol,
        six: firstTenArray[5].symbol,
        seven: firstTenArray[6].symbol,
        eight: firstTenArray[7].symbol,
        nine: firstTenArray[8].symbol,
        ten: firstTenArray[9].symbol,
        pastDate: pastDate,
        currentDate: currentDate,
      })
    );
    setButtonState(true);
    setSpinnerLoad(true);
    setTimeout(() => {
      setScreenStatePortfolio(true);
      setFilteredDataState(false);
      setSpinnerLoad(true);

      setSpinnerLoad(false);

      dispatch(actionsForDatas.requiredReturnPut({ requiredReturn: value4 }));
    }, 2000);
  }
  function actions() {
    // dispatch(actionsForDatas.requiredReturnPut({ requiredReturn: value4 }));
  }

  function handleRowSelected(itm) {
    // console.log(itm.selectedRows);
    dataSymbol = [];
    dataSymbol.push(itm.selectedRows.reverse());
    // console.log(dataSymbol[0]);
    dataTicker = [];
    dataSymbol[0].map((item) => {
      dataTicker.push({
        symbol: item.symbol,
        companyName: item.companyName,
      });
    });
    // console.log(dataTicker.length);

    if (dataTicker.length == 10) {
      setStocksselected(true);
      dispatch(
        actionsForDatas.selectedTickers({ selectedTickers: dataTicker })
      );
    } else {
      setStocksselected(false);
    }
  }
  return (
    <div>
      {/* {screenState && (
        <button className="tryApp1" onClick={goBack} id="backButton">
          GO BACK
        </button>
      )} */}
      {!screenState ? (
        <div className="stockScreener">
          <Ads />
          <div className="upperContentAndButton">
            <Link to="/">
              <button className="tryApp1" onClick={homeClicked}>
                Home
              </button>
            </Link>
            <h1 className="helpLabel">STOCK AND ETF SELECTOR TOOL</h1>
          </div>
          <h3 className="subHeading">
            Answer questions before we find stocks and ETFs tailored to your
            profile.
          </h3>

          <div className="sliderElements">
            <div className="investorTypeSelection">
              <div>
                <div class="header-container">
                  <img
                    className="imageRisk"
                    src={MyImageRisk}
                    alt="Risk logo"
                  />
                  <h4 id="questionsStyling">Choose your investor type</h4>
                </div>
                <h3 id="riskAmount">Amount of risk you are willing to take</h3>
              </div>
              <div className="checkBoxesClass">
                <div className="slider-parent">
                  <h6 id="questionsStyling">Risk Averse</h6>
                  <input
                    className="inputsField"
                    type="checkbox"
                    checked={IndexChange === 0}
                    onChange={() => handleChange(0)}
                  />
                </div>
                <div className="slider-parent">
                  <h6 id="questionsStyling">Risk seeking</h6>
                  <input
                    className="inputsField"
                    type="checkbox"
                    checked={IndexChange === 1}
                    onChange={() => handleChange(1)}
                  />
                </div>
                <div className="slider-parent">
                  <h6 id="questionsStyling">Moderate</h6>
                  <input
                    className="inputsField"
                    type="checkbox"
                    checked={IndexChange === 2}
                    onChange={() => handleChange(2)}
                  />
                </div>
              </div>
            </div>
            <div className="investorTypeSelection">
              <div className="slider-parentFour">
                <div class="header-container">
                  <img
                    className="imageRisk"
                    src={MyImageReturn}
                    alt="Return logo"
                  />
                  <h4 id="questionsStyling">Expected portfolio return</h4>
                </div>
                <h3 id="riskAmount">Your desired portfolio return</h3>
                <div className="checkBoxesClass">
                  <input
                    type="range"
                    min={investorType > 0 ? "15" : "10"}
                    max={investorType > 0 ? "80" : "20"}
                    value={value4}
                    onChange={(e) => setValue4(e.target.value)}
                    onChangeCapture={actions}
                    class="custom-range"
                  />
                  <div className="buble">{value4}</div>
                </div>
              </div>
            </div>

            {value4 ? (
              <button
                className="showTheResultButton"
                id="screenerButton"
                onClick={performAnAction}
              >
                Show the result
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        <div className="screenedResult">
          {filteredDataState &&
            (active ? (
              <MoonLoader
                color={"#003049"}
                loading={spinnerLoad2}
                cssOverride={override}
                size={60}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            ) : (
              <div>
                <Ads />
                <div className="upperContentAndButton">
                  <Link to="/">
                    <button className="tryApp1" onClick={homeClicked}>
                      Home
                    </button>
                  </Link>
                  <h1 className="helpLabel">SELECTED STOCKS AND ETFS</h1>
                </div>
                <h3 className="subHeading">
                  Stocks and ETFs filtered for you based on the questions that
                  you answered
                </h3>
                <div className="centeredDataTable">
                  <div className="dataTable">
                    <DataTable
                      columns={columns}
                      data={data}
                      customStyles={customStyles}
                      onSelectedRowsChange={handleRowSelected}
                      selectableRows
                      fixedHeader
                      theme="solarized"
                    />
                  </div>
                </div>
                {!buttonState ? (
                  <button
                    className="showTheResultButton"
                    id="buildThePortfolio"
                    onClick={setStateForPortfolio}
                  >
                    Portfolio builder
                  </button>
                ) : (
                  <MoonLoader
                    color={"#003049"}
                    loading={spinnerLoad}
                    cssOverride={override}
                    size={60}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                )}
              </div>
            ))}
          {screenStatePortfolio && <EfficientPortfolio />}
        </div>
      )}
    </div>
  );
};
export default StockScreener;
