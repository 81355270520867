import logo from "./logo.svg";
import "./App.scss";
import NavigationModule from "./ApperBar";
import MainPageContent from "./MainPageContent";
import HelpComponent from "./Help";
import React, { useState, useEffect } from "react";
import IOSAppsComponent from "./IOSApps";
import ForecasterAndAnalytics from "./Forecaster";
import StockScreener from "./stockScreenerAndPortfolioBuilder/StockAndETFScreener";
// import AdsBlocker from "./AdsBlocker";
// import { useDetectAdBlock } from "adblock-detect-react";
import EfficientPortfolio from "./stockScreenerAndPortfolioBuilder/EfficientPortfolioBuilder";
import BlogSection from "./Blog";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import BlogList from "./BlogLst";

function App() {
  // const adBlockDetected = useDetectAdBlock();
  const [currentComponent, setCurrentComponent] = useState("A");
  const handleComponentChange = (newComponent) => {
    setCurrentComponent(newComponent);
  };
  const forecastPath = window.location.pathname === "/ideas";
  const blogPath = window.location.pathname === "/stockMarketBlog";
  const iosAppsPath = window.location.pathname === "/stockMarketiOSApps";
  const diverssetPath =
    window.location.pathname === "/stockScreenerAndPortfolioBuilder";
  const helpPath = window.location.pathname === "/help";

  useEffect(() => {
    if (helpPath) {
      handleComponentChange("B");
    }
    if (diverssetPath) {
      handleComponentChange("E");
    }
    if (iosAppsPath) {
      handleComponentChange("C");
    }
    if (blogPath) {
      handleComponentChange("F");
    }
    if (forecastPath) {
      handleComponentChange("D");
    }
  }, []);

  return (
    <BrowserRouter>
      <div className="App">
        {/* {adBlockDetected ? (
          <AdsBlocker />
        ) : ( */}
        <div>
          <NavigationModule
            onHelpClick={() => handleComponentChange("B")}
            onIOSClick={() => handleComponentChange("C")}
            onForecastClick={() => handleComponentChange("D")}
            tryDiverssetClicked={() => handleComponentChange("E")}
            onBlockClicked={() => handleComponentChange("F")}
          />
          <Routes>
            <Route
              path=""
              element={
                currentComponent === "A" && (
                  <MainPageContent
                    tryDiverssetClicked={() => handleComponentChange("E")}
                  />
                )
              }
            />

            <Route
              path="help"
              element={
                currentComponent === "B" && (
                  <HelpComponent
                    homeClicked={() => handleComponentChange("A")}
                  />
                )
              }
            />

            <Route
              path="stockMarketiOSApps"
              element={
                currentComponent === "C" && (
                  <IOSAppsComponent
                    homeClicked={() => handleComponentChange("A")}
                  />
                )
              }
            />

            <Route
              path="/ideas"
              element={
                currentComponent === "D" && (
                  <ForecasterAndAnalytics
                    homeClicked={() => handleComponentChange("A")}
                  />
                )
              }
            />

            <Route
              path="stockScreenerAndPortfolioBuilder"
              element={
                currentComponent === "E" && (
                  <StockScreener
                    homeClicked={() => handleComponentChange("A")}
                  />
                )
              }
            />
            <Route
              path="/blog/:heading"
              element={
                <BlogList onBlockClicked={() => handleComponentChange("F")} />
              }
            />

            <Route
              path="stockMarketBlog"
              element={
                currentComponent === "F" && (
                  <BlogSection homeClicked={() => handleComponentChange("A")} />
                )
              }
            />
          </Routes>
        </div>
        {/* )} */}
      </div>
    </BrowserRouter>
  );
}

export default App;
