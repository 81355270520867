import "./App.scss";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { collection, getDocs } from "firebase/firestore";
import { useEffect, useState } from "react";
import AdsBlocker from "./AdsBlocker";
import { useDetectAdBlock } from "adblock-detect-react";
import Ads from "./AdsComponent";
import DOMPurify from "dompurify";
import { Link } from "react-router-dom";
import MoonLoader from "react-spinners/ClipLoader";
import { getBlogPosts } from "./stockScreenerAndPortfolioBuilder/sourceForEndpoint";
import { useDispatch, useSelector } from "react-redux";

const BlogSection = ({ homeClicked }) => {
  const dispatch = useDispatch();
  const adBlockDetected = useDetectAdBlock();
  const [blogPosts, setBlogPosts] = useState([]);
  const [blogStatus, setBlogStatus] = useState(blogPosts.map(() => false));
  const [rotatingSpinner, setRotatingSpinner] = useState(false);

  const maxLines = 2; // Set the maximum number of lines to display

  const containerStyle = {
    display: "flex", // Use flex display
    alignItems: "center", // Center vertically
  };

  const imageStyle = {
    width: "20%",
    height: "auto",
    marginRight: "10px", // Add margin to create space between image and postBody
  };

  const postBodyStyle = {
    flex: "1", // Allow postBody to expand and fill remaining space
    maxHeight: `${maxLines * 2}em`, // Adjust the line height as needed
    overflow: "hidden",
  };

  const blogStatusChange = (index) => {
    // Create a copy of the current status array
    const newStatusArray = [...blogStatus];
    // Toggle the status for the clicked index
    newStatusArray[index] = !newStatusArray[index];
    // Update the status array
    setBlogStatus(newStatusArray);
  };
  const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    marginTop: "15%",
  };
  const blogs = useSelector((state) => state.filtered.blogPosts);

  useEffect(() => {
    if (blogPosts.length == 0) {
      setRotatingSpinner(true);
      dispatch(getBlogPosts({}));

      setTimeout(() => {
        if (blogs) {
          setBlogPosts(blogs);
        }
      }, "500");
    } else {
      setRotatingSpinner(false);
    }
  }, [blogs]);
  // useEffect(() => {
  //   if (blogPosts.length > 0) {

  //   }
  // }, [dispatch]);

  const sanitizeHtml = (html) => {
    return DOMPurify.sanitize(html);
  };

  return (
    <div>
      {adBlockDetected ? (
        <AdsBlocker />
      ) : (
        <div>
          <Ads />

          <div className="upperContentAndButton">
            <Link to="/">
              <button className="tryApp1" onClick={homeClicked}>
                Home
              </button>
            </Link>
            <h1 className="helpLabel">STOCK MARKET BLOG</h1>
          </div>
          <h3 className="subHeading">
            Stock market-related blog. Equity valuation, risk management,
            portfolio management, trading.
          </h3>
          {blogPosts.length > 0 ? (
            <div className="buttonContainer">
              <div className="blog-list">
                {blogPosts.map((post, index) => (
                  <div
                    className="blog-post"
                    key={index}
                    onClick={() => blogStatusChange(index)}
                  >
                    <Link to={`/blog/${post.heading}`}>
                      <h1>{post.heading.toUpperCase()}</h1>
                    </Link>
                    {blogStatus[index] ? (
                      <div>
                        <img
                          src={`data:image/jpeg;base64,${post.picture}`} // Assuming JPEG format
                          alt={`Post ${index}`}
                        />
                        <div
                          className="postBody"
                          dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(post.body),
                          }}
                        />
                      </div>
                    ) : (
                      <div style={containerStyle}>
                        <img
                          src={`data:image/jpeg;base64,${post.picture}`} // Assuming JPEG format
                          alt={`Post ${index}`}
                          style={imageStyle}
                        />
                        <div
                          className="postBody"
                          dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(post.body),
                          }}
                          style={postBodyStyle}
                        />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <MoonLoader
              color={"#003049"}
              loading={rotatingSpinner}
              cssOverride={override}
              size={60}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          )}
        </div>
      )}
    </div>
  );
};

export default BlogSection;
