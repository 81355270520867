import React from "react";

const MarketForecasts = (props) => {
  const handleClick = () => {
    // Call the action function passed via props
    if (props.onClickAction) {
      props.onClickAction();
    }
  };
  return (
    <div className="forecastsContainer">
      <div className="forecastData">
        <svg
          viewBox="0 0 24 24"
          stroke="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          color="currentColor"
          width="24"
          height="24"
          onClick={handleClick}
        >
          <path d="M9 9L15 15"></path>
          <path d="M15 9L9 15"></path>
          <path d="M3 12C3 4.5885 4.5885 3 12 3C19.4115 3 21 4.5885 21 12C21 19.4115 19.4115 21 12 21C4.5885 21 3 19.4115 3 12Z"></path>
        </svg>
        <h4>{props.header}</h4>
        <h5>{props.body}</h5>
      </div>
    </div>
  );
};
export default MarketForecasts;
